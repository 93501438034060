import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import authService from "../services/authService";
import {
  BiRightArrow,
  BiRupee,
  BiSolidMap,
  BiSolidRightArrow,
} from "react-icons/bi";
import TokenCard from "./TokenCard";
import TokenCreateCard from "./TokenCreateCard";
import doctorService from "../services/doctorService";
import axios from "axios";
import { BASE_URL } from "../utils";

const DoctorProfile = () => {
  const { doctorName, doctorId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const [tokenData, setTokenData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isTrue, setIsTrue] = useState(false);
  const [shiftId, setShiftId] = useState(false);

  const handelmodalcontain = () => {
    setIsTrue(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleBookNowClick = (shift_id) => {
    setShiftId(shift_id);
    setIsTrue(true);
    setShowModal(true);
  };
  const getData = async () => {
    setIsLoading(true);
    const reqeustParam = { doctorId: doctorId };
    const resp = await authService.getDoctorDetails(reqeustParam);
    const resData = await resp.json();
    if (resData.success) {
      setDetails(resData.data);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      {!isLoading ? (
        <Container className="py-4">
          {details.name ? (
            <Card className="p-4 shadow-sm">
              <Row>
                <Col xs={12} md={12} lg={4} className="px-0 d-lg-none">
                  <Card className="px-2 mb-3">
                    <TokenCard doctor={details} setTokenData={setTokenData} />
                  </Card>
                  {tokenData.patient && (
                    <div>
                      <TokenDetail tokenData={tokenData} />
                    </div>
                  )}
                </Col>
                <Col xs={12} md={12} lg={8} className="px-0">
                  <Row>
                    <Col xs={12} md={3} lg={3} className="text-center">
                      <img
                        src={details.profile || "../../doct.png"}
                        alt="doctor profile"
                        className="ps-2 mb-4"
                        style={{
                          width: "150px",
                          borderRadius: "10%",
                          border: "0px solid #00a19a",
                        }}
                      />
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <div className="pe-2">
                        <h5 style={{ color: "#00a19a" }}>
                          {details?.name || doctorName}
                        </h5>
                        <div className="mt-2" style={{ color: "#555" }}>
                          {details?.location?.category?.name}
                        </div>
                        <div className="mt-2" style={{ color: "#555" }}>
                          <strong>Contact - </strong> {details.mobile}
                        </div>
                        <div className="mt-2" style={{ color: "#555" }}>
                          <strong>About - </strong> {details?.location?.about}
                        </div>
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className="py-3">
                        <h5>Specialization :-</h5>
                        <hr className="my-0 pb-2" />
                        <div className="row">
                          {details.specialists.map((field, index) => (
                            <div className="col-md-6">
                              <p className="mb-1">
                                <BiSolidRightArrow /> {field}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                      {tokenData.patient && (
                        <div className="px-2 d-none d-lg-block mt-4">
                          <TokenDetail tokenData={tokenData} />
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={9} lg={4} className="px-0 d-none d-lg-block">
                  <Card className="px-2">
                    <TokenCard doctor={details} setTokenData={setTokenData} />
                  </Card>
                </Col>
              </Row>
              {/* Table for Shifts, Time, Fee, and Location */}
              <h4 className="my-3" style={{ color: "#00a19a" }}>
                Working Hours
              </h4>
              <Row>
                {details.shifts &&
                  details.shifts.map((item) => (
                    <Col
                      key={item.id}
                      xs={12}
                      md={4}
                      lg={3}
                      className="px-0 mb-3"
                    >
                      <Card className="me-1" style={{ borderColor: "#00a19a" }}>
                        <p
                          className="text-center"
                          style={{
                            color: "#00a19a",
                            borderBottom: "1px solid #00a19a",
                          }}
                        >
                          {item?.hospital_name}
                        </p>
                        <div className="px-2">
                          <p className="my-1" style={{ color: "#00a19a" }}>
                            Time - {item?.from}
                          </p>
                          <p className="my-1" style={{ color: "#00a19a" }}>
                            Fee - ₹{item?.fees}
                          </p>
                          <p className="my-1" style={{ color: "#00a19a" }}>
                            Location - {item?.address}
                            <a
                              href={item?.map_location}
                              target="_blank"
                              className="text-decoration-none"
                            >
                              <BiSolidMap />
                            </a>
                          </p>
                        </div>
                        <div className="text-end p-1">
                          {details.online_booking == 1 && (
                            <Button
                              onClick={() => handleBookNowClick(item.id)}
                              className="btn-sm btn-warning m-0 py-2 w-100 text-black"
                            >
                              <b className="blink">नंबर लगाएं</b>
                            </Button>
                          )}
                        </div>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </Card>
          ) : (
            <div className="text-center" style={{ paddingTop: "15%" }}>
              <img src="../../404.webp" alt="doctor profile" className="" />
              <h2 className="text-danger mt-4">Doctor not found . . .</h2>
            </div>
          )}
        </Container>
      ) : (
        <div className="text-center" style={{ paddingTop: "20%" }}>
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <TokenCreateCard
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        selectedDoctor={details}
        isTrue={isTrue}
        handelmodalcontain={handelmodalcontain}
        shiftId={shiftId}
      />
    </div>
  );
};

const TokenDetail = ({ tokenData }) => {
  const [loading, setLoading] = useState(false);
  const replaceToken = (e) => {
    e.preventDefault();
    const token_replace_id = e.target.token_replace_id.value;
    if (token_replace_id != null) {
      const reqeustParam = {
        doctor_id: tokenData.doctor_id,
        token_id: tokenData.id,
        token_replace_id: token_replace_id,
      };
      setLoading(true);
      const url = `${BASE_URL}/replace-token`;
      axios
        .post(url, reqeustParam, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.data && response.data.data) {
            window.location.href = response.data.data;
          }
        });
      setLoading(false);
    }
  };

  console.log("loading", loading);

  return (
    <div className="w-100 pb-2 ">
      <hr />
      {tokenData.patient_in == 1 && (
        <div
          className="p-3 text-danger mb-3"
          style={{ border: "2px solid red" }}
        >
          <h4>
            You want to replace your current token with a different token.
          </h4>
          <form onSubmit={replaceToken}>
            <div className="row">
              <div className="col-lg-6">
                <select name="token_replace_id" className="form-control">
                  <option value="">Select Token</option>
                  {tokenData.tokens &&
                    tokenData.tokens.map((item, index) => (
                      <option key={index} value={item.id}>
                        Token-{item.token_number}, Time-{item.expected_time}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-lg-6">
                <button
                  className="btn btn-primary py-1"
                  type="submit"
                  disabled={loading}
                >
                  {loading
                    ? "Loading..."
                    : "₹" + tokenData.replace_free + " Pay Now"}
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
      <h4>TOKEN DETAILS</h4>
      <div>
        <h6 className="text-danger">
          Note :- Please note that the appointment time may vary slightly based
          on the Doctor's availability and patient flow. We will do our best to
          keep you updated and minimize any wait time.
        </h6>
      </div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Patient Name</th>
            <th>{tokenData?.patient?.name}</th>
          </tr>
          <tr>
            <th>Token Number</th>
            <th>{tokenData?.token_number}</th>
          </tr>
          <tr>
            <th>Token Status</th>
            <th>
              {tokenData?.is_type == "Skip" ? "Skipped" : tokenData?.is_type}
            </th>
          </tr>
          <tr>
            <th>Expected Time</th>
            <th>{tokenData?.expected_time}</th>
          </tr>
          <tr>
            <th>Left Time</th>
            <th>{tokenData?.left_time}</th>
          </tr>
          <tr>
            <th>No. of Patient (before you)</th>
            <th>{tokenData?.patientTotal}</th>
          </tr>
          <tr>
            <th>Arrived at Clinic</th>
            <th>{tokenData?.patientIn}</th>
          </tr>
        </thead>
      </table>
      {/* <a className="d-flex justify-content-end text-decoration-none">
                {isLoading ? (
                  <span className="spinner-border spinner-border-sm mt-1"></span>
                ) : (
                  <BiRefresh size={24} />
                )}
                &nbsp;Refresh
              </a> */}
      <h5 className="text-danger text-center">समय में बदलाव संभव है।</h5>
      <hr />
    </div>
  );
};

export default DoctorProfile;
